import React, { useState, useEffect, useMemo, useRef  } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; 
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './index.css';
import { getUserIdFromToken } from '../utils';

const LineItemsGrid = ({ rowData, changeOrder, job, estimateAccepted, selectLineItemsMode, selectedLineItems, onLineItemSelection, updatePermitTotal, permitRequired, setFormData, discount, discount_description, handleDiscountInput }) => {        
    const [gridData, setGridData] = useState([]);
    const [reArrange, setReArrange] = useState(false);
    const [isEditable, setIsEditable] = useState(changeOrder || !estimateAccepted);
    const [hiddenColumns, setHiddenColumns] = useState([]);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [otherState, setOtherState] = useState(false);
    const [locked, setLocked] = useState(false);
    const gridRef = useRef(null);

    const updateGridData = (data) => {
        const sortedData = sortAndUpdateLineNumbers(data);
        setGridData([...sortedData, ...getFooterRows(sortedData)]);
    };

    useEffect(() => {
        updateGridData(rowData);
        const newIsEditable = (changeOrder && !estimateAccepted) || !selectLineItemsMode;
    
        // Only update if there's a change
        if (isEditable !== newIsEditable) {
            setIsEditable(newIsEditable);
        }
    
        if (!permitRequired) {
            setHiddenColumns(['permit_price']);
        } else {
            setHiddenColumns([]);
        }
    
        if (gridRef.current && gridRef.current.api) {
            gridRef.current.api.sizeColumnsToFit();
        }
    }, [rowData, changeOrder, estimateAccepted, selectLineItemsMode, selectedLineItems, permitRequired, discount, discount_description]);
    

    const toggleOther = (e) => {
        e.preventDefault();
        setFormData((prevInputs) => ({
            ...prevInputs,
            'permit_required': !permitRequired
        }));
    };

    const sortAndUpdateLineNumbers = (data) => {
        return data
            .filter(item => !item.isFooter)
            .sort((a, b) => a.line_item_number - b.line_item_number)
            .map((item, index) => ({ ...item, line_item_number: index + 1 }));
    };

    const calculateTotalPrice = (item) => {
        const laborPrice = parseFloat(item.labor_price) || 0;
        const materialsPrice = parseFloat(item.materials_price) || 0;
        const equipmentPrice = parseFloat(item.equipment_price) || 0;
        const permitPrice = parseFloat(item.permit_price) || 0;
        return laborPrice + materialsPrice + equipmentPrice + permitPrice;
    };

    const calculateSumsAndPercentages = (data) => {
        const totals = {
            labor_price: 0,
            materials_price: 0,
            equipment_price: 0,
            permit_price: 0,
            total_price: 0,
        };

        console.log(data);

        data.forEach(item => {
            totals.labor_price += parseFloat(item.labor_price) || 0;
            totals.materials_price += parseFloat(item.materials_price) || 0;
            totals.equipment_price += parseFloat(item.equipment_price) || 0;
            totals.permit_price += parseFloat(item.permit_price) || 0;
            totals.total_price += calculateTotalPrice(item);
        });

        console.log(totals);

        updatePermitTotal(totals.permit_price);

        const percentages = {
            labor_price: 0,
            materials_price: 0,
            equipment_price: 0,
            permit_price: 0,
            total_price: 0
        };

        console.log(totals.total_price);
        const grandTotal = totals.total_price;
        console.log(discount);
        console.log(grandTotal);
        if (grandTotal > 0) {
            percentages.labor_price = (totals.labor_price / grandTotal) * 100;
            percentages.materials_price = (totals.materials_price / grandTotal) * 100;
            percentages.equipment_price = (totals.equipment_price / grandTotal) * 100;
            percentages.permit_price = (totals.permit_price / grandTotal) * 100;
            percentages.total_price = (totals.total_price / grandTotal) * 100;
        }

        return { totals, percentages };
    };

    const getFooterRows = (data) => {
        const { totals, percentages } = calculateSumsAndPercentages(data);
        return [
            {
                description: 'Totals',
                labor_price: totals.labor_price,
                materials_price: totals.materials_price,
                equipment_price: totals.equipment_price,
                permit_price: totals.permit_price,
                total_price: totals.total_price,
                isFooter: true,
                editable: false
            },
            {
                description: 'Percentages',
                labor_price: percentages.labor_price.toFixed(2) + '%',
                materials_price: percentages.materials_price.toFixed(2) + '%',
                equipment_price: percentages.equipment_price.toFixed(2) + '%',
                permit_price: percentages.permit_price.toFixed(2) + '%',
                total_price: percentages.total_price.toFixed(2) + '%',
                isFooter: true,
                editable: false
            },
            {
                description: 'Discount',
                labor_price: null,
                materials_price: null,
                equipment_price: null,
                permit_price: null,
                total_price: discount,
                isFooter: true,
                editable: false,
                job: job
            },
        ];
    };

    const handleRowClick = (params) => {
        if (isEditable) return;

        if (onLineItemSelection) {
            onLineItemSelection(params.data);
        }
    };
    
    const columnDefs = [
        {
            headerName: "#", 
            field: 'line_item_number',
            pinned: 'left',
            width: 50,  // Set a fixed width for the index column
            sortable: false, // Indexes typically don't need to be sortable
            filter: false,  // Filtering not required for index numbers
            rowDrag: reArrange
        },
        { headerName: "Description", field: "description", editable: isEditable, minWidth: 240, pinned: 'left' },
        { headerName: "Labor $", field: "labor_price", editable: isEditable, minWidth: 120 },
        { headerName: "Materials $", field: "materials_price", editable: isEditable, minWidth: 120 },
        { headerName: "Equipment $", field: "equipment_price", editable: isEditable, minWidth: 120 },
        { headerName: "Other $", field: "permit_price", editable: isEditable, minWidth: 120, hide: !permitRequired },
        { headerName: "Total $", field: "total_price", editable: (params) => params.data.description == 'Discount', minWidth: 120 }
    ].map(col => ({
        ...col,
        hide: hiddenColumns.includes(col.field)
    }));

    const onCellValueChanged = (params) => {
        console.log('onCellValueChanged' + params);
        const token = localStorage.getItem('access_token');

        if (params.data.description === 'Discount') {
            var url = `${window.location.protocol}//${window.location.hostname}:8443/api/jobs/${params.data.job}/`;
            var body = {
                user: getUserIdFromToken(token),
                company: '',
                job: params.data.job,
                discount: params.data.total_price
            }
            console.log(body);

            fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(body)
            })
            .then(response => response.json())
            .then(data => {
                handleDiscountInput(data.discount);
            })
            .catch(error => console.error('Update failed for:', body, error));
        } else {
            var url = `${window.location.protocol}//${window.location.hostname}:8443/api/joblineitems/${params.data.id}/`;
        
            if (['labor_price', 'materials_price', 'equipment_price', 'permit_price'].includes(params.colDef.field)) {
                params.data.total_price = calculateTotalPrice(params.data);
            }
        
            fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(params.data)
            })
            .then(response => response.json())
            .then(data => {
                const updatedRowData = gridData.map(item => {
                    if (item.id === data.id) {
                        return { ...item, ...data };
                    }
                    return item;
                });
            })
            .catch(error => console.error('Update failed for:', params.data, error));
        }
    };

    const onRowDragEnd = (params) => {
        const updatedData = params.api.getRenderedNodes().map((node, index) => {
            const data = node.data;
            if (!data.isFooter) {
                data.line_item_number = index + 1;
            }
            return data;
        });
        updateGridData(updatedData);
    
        const token = localStorage.getItem('access_token');
        updatedData.forEach((data) => {
            if (!data.isFooter) {
                const url = `${window.location.protocol}//${window.location.hostname}:8443/api/joblineitems/${data.id}/`;
    
                fetch(url, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({ line_item_number: data.line_item_number })
                })
                .then(response => response.json())
                .then(data => {})
                .catch(error => console.error('Update failed for:', data, error));
            }
        });
    };

    const gridOptions = useMemo(() => ({
        onGridSizeChanged: params => {
            params.api.sizeColumnsToFit();
        },
        
        singleClickEdit: isEditable,
        suppressMovableColumns: true,    // Disable column moving
        suppressDragLeaveHidesColumns: true, // Prevent columns from hiding when dragged
        stopEditingWhenCellsLoseFocus: false,
        suppressCellFocus: false,
        suppressClickEdit: !isEditable,
        enterMovesDownAfterEdit: true,
        valueSetter: (params) => {
            if (params.newValue !== params.oldValue) {
                params.data[params.colDef.field] = params.newValue;
                return true;
            }
            return false;
        },
        rowDragManaged: true,
        animateRows: true,
        onRowDragEnd: onRowDragEnd,
        onRowClicked: handleRowClick, // Add this to handle row clicks
        // Allows you to move to the next cell with Tab
        tabToNextCell: (params) => {
            const nextCell = params.nextCellPosition;
            return nextCell;
        },
    }), [isEditable]);

    const addRow = (e) => {
        e.preventDefault();
    
        const newRow = {
            job: job,
            line_item_number: gridData.filter(item => !item.isFooter).length + 1,
            description: '',
            labor_price: 0,
            materials_price: 0,
            equipment_price: 0,
            total_price: 0
        };
    
        const token = localStorage.getItem('access_token');
        const url = `${window.location.protocol}//${window.location.hostname}:8443/api/joblineitems/`;
    
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(newRow)
        })
        .then(response => response.json())
        .then(data => {
            updateGridData([data, ...gridData.filter(item => !item.isFooter)]);
        })
        .catch(error => console.error('Add row failed:', newRow, error));
    };

    const removeLastRow = (e) => {
        e.preventDefault();
    
        const nonFooterData = gridData.filter(item => !item.isFooter);
        const lastRow = nonFooterData[nonFooterData.length - 1];
        
        if (lastRow) {
            const token = localStorage.getItem('access_token');
            const url = `${window.location.protocol}//${window.location.hostname}:8443/api/joblineitems/${lastRow.id}/`;
    
            fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            })
            .then(response => {
                if (response.ok) {
                    const newData = nonFooterData.slice(0, -1);
                    updateGridData(newData);
                } else {
                    console.error('Failed to delete the last row:', lastRow);
                }
            })
            .catch(error => console.error('Delete row failed:', lastRow, error));
        }
    };

    const toggleReArrange = (e) => {
        e.preventDefault();
        setReArrange(!reArrange);
    };

    const getRowClass = (params) => {
        if (params.data.isFooter) return 'no-drag';
        if (params.data.partial) return 'partial-row';
        if (params.data.final) return 'final-row';

        for(let i=0; i<selectedLineItems.length; i++) {
            if (selectedLineItems[i].id === params.data.id) {
                return 'currently-selected-row';
            }
        }

        if (params.data.incurred) return 'incurred-row';

        return '';
    };

    const handleScroll = (e, direction) => {
        e.preventDefault();
        if (gridRef.current) {
            const gridElement = gridRef.current.querySelector('.ag-body-horizontal-scroll-viewport');
            if (gridElement) {
                const newScrollPosition = gridElement.scrollLeft + direction * 120; // Adjust the scroll amount as needed
                gridElement.scrollLeft = newScrollPosition;
                setScrollPosition(newScrollPosition);
            }
        }
    };

    // Toggle lock state when checkbox is clicked
    const handleLockToggle = () => {
        //setLocked((prevLocked) => !prevLocked);
    };

    return (
        <div className="ag-theme-alpine" style={{ height: 'auto', width: '100%' }}>
        { !estimateAccepted || changeOrder ? ( 
            <div className="vertical-container">
                <div>
                    <button className="button line-item-button " onClick={(e) => addRow(e)}>
                        + Add
                    </button>
                </div>
                <div>
                    <button className="button line-item-button " onClick={(e) => removeLastRow(e)}>
                        - Remove Last
                    </button>
                </div>
                <div>
                    <button className="button line-item-button " onClick={(e) => toggleReArrange(e)}>
                        Re-Arrange
                    </button>
                </div>
                <div>
                    <button className="button line-item-button " onClick={(e) => toggleOther(e)}>
                        Other
                    </button>
                </div>
            </div>
        ) : (
        <div className="vertical-container">
            <div>
                <button className="button disabled line-item-button " onClick={(e) => addRow(e)} disabled>
                    + Add
                </button>
            </div>
            <div>
                <button className="button disabled line-item-button " onClick={(e) => removeLastRow(e)} disabled>
                    - Remove Last
                </button>
            </div>
            <div>
                <button className="button disabled line-item-button " onClick={(e) => toggleReArrange(e)} disabled>
                    Re-Arrange
                </button>
            </div>
            <div>
                    <button className="button line-item-button " onClick={(e) => toggleOther(e)}>
                        Other
                    </button>
            </div>
        </div>
        )}
            <div ref={gridRef} style={{ width: '100%', height: '100%' }}>
                {locked && <div className="overlay"></div>}
                <AgGridReact
                    key={JSON.stringify({gridData, isEditable})}
                    columnDefs={columnDefs}
                    defaultColDef={{
                        resizable: false,
                        sortable: false,
                        filter: false,
                        flex: 1,
                        editable: isEditable
                    }}
                    rowData={gridData}
                    domLayout='autoHeight'
                    animateRows={true}
                    filter={false}
                    pagination={false}
                    onCellValueChanged={onCellValueChanged}
                    paginationPageSize={10}
                    gridOptions={gridOptions}
                    getRowClass={getRowClass}
                    onRowClicked={handleRowClick}>
                </AgGridReact>
            </div>
            <div className="scroll-buttons">
                <button href="#" onClick={(e) => handleScroll(e, -1)}>&lt; Previous</button>
                <button href="#" onClick={(e) => handleScroll(e, 1)}>Next &gt;</button>
            </div>
        </div>
    );
};

export default LineItemsGrid;
