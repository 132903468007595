import React, { useState, useMemo, useRef, useCallback, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import './index.css';

const PortalPage = () => {
  const gridRef = useRef();
  const [searchTerm, setSearchTerm] = useState('');
  const [rowData, setRowData] = useState([]);

  
  const columnDefs = useMemo(
    () => [
      { field: 'job_number', headerName: 'Job Number', filter: true },
      { field: 'customer_reported', headerName: 'Customer Reported', filter: true },
      { field: 'job_site_address', headerName: 'Job Site Address', filter: true },
    ],
    []
  );

  // Replace this with however you're handling auth (token, cookies, etc.)
  // If you have a token, you'd pass: 'Authorization': `Bearer ${token}`
  useEffect(() => {
    const token = localStorage.getItem('access_token');
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
    });

    fetch(`${window.location.protocol}//${window.location.hostname}:8443/api/jobs/`, {
      method: 'GET',
      headers: headers
      // credentials might be needed if you're using session-based auth or CSRF:
      // credentials: 'include',
    })
      .then((response) => response.json())
      .then((data) => {
        // `data` is an array of job objects from your Django REST API
        const filteredData = data.map((job) => ({
          job_site_address: job.job_site_address,
          job_number: job.job_number,
          customer_reported: job.customer_reported,
          link: `.#job${job.id}`,
        }));
        setRowData(filteredData);
      })
      .catch((error) => {
        console.error('Error fetching jobs:', error);
      });
  }, []);

  const onFilterTextChange = useCallback(
    (e) => {
      setSearchTerm(e.target.value);
      gridRef.current.api.setQuickFilter(e.target.value);
    },
    []
  );

  const handleRowClick = (event) => {
    const link = event.data.link;
    if (link) {
      window.location.href = link; // or use navigate(link) if using React Router
    }
  };

  return (
    <div className="container-portal">
      <input
        type="text"
        value={searchTerm}
        onChange={onFilterTextChange}
        placeholder="Search..."
        className="border rounded p-2 mb-2 mt-4 w-full"
        style={{ width: '72%' }}
      />
      <div className="ag-theme-alpine search-portal">
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          onRowClicked={handleRowClick}
          defaultColDef={{ flex: 1, minWidth: 100 }}
        />
      </div>
    </div>
  );
};

export default PortalPage;
