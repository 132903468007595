import React from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useNavigate } from 'react-router-dom';

const PayPal = ({ invoice }) => {
    const navigate = useNavigate();
    
    const handleCancel = () => {
        navigate('/payment-cancel', { replace: true });
    };

    const handleSuccess = () => {
        navigate('/payment-success', { replace: true });
    };

    return (
    <PayPalScriptProvider options={{ "client-id": "ASofAWRseWKpZhP4HaUB3grnUS9KIoYNRN_NFHTDMg19LaKp3EetMnFAifuaR15zSD_gMu-MHqZ9t2rj" }}>
      <PayPalButtons
        createOrder={(data, actions) => {
          return fetch(`${window.location.protocol}//${window.location.hostname}:8443/api/create-payment/`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              "guid": `${invoice.guid}`
            }),
          })
            .then((res) => res.json())
            .then(data => {
                console.log(`Data: ${data}`);
                if (data.id) {
                console.log(data);
                  return data.id; // Return the order ID to PayPal
                } else {
                  throw new Error('Order ID not received from backend.');
                }
            });
        }}
        onApprove={(data, actions) => {
          return fetch(`${window.location.protocol}//${window.location.hostname}:8443/api/capture-payment/`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              "payment_id": data.orderID,
              "payer_id": data.payerID,
              "invoice_guid": invoice.guid
            }),
          })
            .then((res) => res.json())
            .then((details) => {
              handleSuccess();
            });
        }}

        onCancel={(data) => {
          handleCancel();
        }
        }
      />
    </PayPalScriptProvider>
  );
};

export default PayPal;
