import React from 'react';
import { useNavigate } from 'react-router-dom';

const PaymentSuccessPage = () => {
    const navigate = useNavigate();

    const handleGoBack = () => {
         navigate('/', { replace: true });
    };

    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <h1>Payment Successful!</h1>
            <p>Thank you for your purchase. Your payment has been processed successfully.</p>
            <button onClick={handleGoBack} style={{ padding: '10px 20px', fontSize: '16px' }}>
                Go to Home
            </button>
        </div>
    );
};

export default PaymentSuccessPage;