import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-modal";
import LineItemsGrid from "../lineItemsGrid";
import payIcon from "./payIcon.png";
import openLock from "./openLock.png";
import closedLock from "./closedLock.png";
import chevronRight from "./chevronRight.png";
import chevronDown from "./chevronDown.png";
import "./index.css";
import { getUserIdFromToken } from "../utils";
import { QRCodeCanvas } from "qrcode.react";

const EditJob = ({ jobData, refreshTables }) => {
  const [formData, setFormData] = useState({
    date_assigned: "",
    status: "",
    client: "",
    job_site_address: "", // filled
    bill_to_name: "",
    bill_to_address: "",
    bill_to_phone_number: "",
    bill_to_email_address: "",
    customer_reported: "",
    land_owner_name: "",
    land_owner_address: "",
    land_owner_phone_number: "",
    land_owner_email_address: "",
    resident_name: "",
    resident_phone_number: "",
    resident_email_address: "",
    scope_of_work: "",
    year_built: "", // filled
    estimate_accepted: false,
    estimate_accepted_date: "",
    estimate_rejected: false,
    estimate_rejected_date: "",
    contractor: "", // filled
    permit_required: false, // filled
    permit_description: "",
    permit_total: "0",
    emergency: false,
    water: "", // filled
    sewer: "",
    completion_date: "", // filled
    latitude: 0,
    longitude: 0,
    gate_code: "", // filled
    lockbox_code: "",
    number_of_bedrooms: "0", // filled
    number_of_bathrooms: "0", // filled
    square_footage: "0", // filled
    discount: 0,
    discount_description: "",
    lineItems: [],
    custom_entry: "",
    year_built_on_invoice: false,
    square_footage_on_invoice: false,
    number_of_bedrooms_on_invoice: false,
    number_of_bathrooms_on_invoice: false,
    water_on_invoice: false,
    sewer_on_invoice: false,
    gate_code_on_invoice: false,
    lockbox_code_on_invoice: false,
    custom_entry_on_invoice: false,
  });

  const [lineItems, setLineItems] = useState([]);
  const [selectLineItemsMode, setSelectLineItemsMode] = useState(false);
  const [completedLineItems, setCompletedLineItems] = useState(0);
  const [selectedLineItems, setSelectedLineItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isCompletionModalOpen, setIsCompletionModalOpen] = useState(false);
  const [qrCodeModalIsOpen, setQrCodeModalIsOpen] = useState(false);
  const [phoneNumberModal, setPhoneNumberModal] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState("");

  const [estimateData, setEstimateData] = useState({
    percentage: "",
    issue_date: "",
    due_date: "",
    amount_due: "",
    amount_paid: "0.00",
    invoice_sent: false,
    invoice_type: "EST",
  });
  const [totalAmount, setTotalAmount] = useState(0);

  const [paymentMethod, setPaymentMethod] = useState("cash");
  const [paymentFormData, setPaymentFormData] = useState({
    payment_method: "",
    amount: "",
    invoice: "",
    check_number: "",
    name_on_card: "",
    card_number: "",
    card_expiry: "",
    card_cvc: "",
  });

  const [changeOrder, setChangeOrder] = useState(false);
  const [visibleBillTo, setVisibleBillTo] = useState(false);
  const [visibleLandOwner, setVisibleLandOwner] = useState(false);
  const [visibleResident, setVisibleResident] = useState(false);
  const [visibleProperty, setVisibleProperty] = useState(true);
  const [visibleContractor, setVisibleContractor] = useState(true);
  const [visibleKey, setVisibleKey] = useState(true);
  const [visibleLineItems, setVisibleLineItems] = useState(true);
  const [visibleEstimatesAndInvoices, setVisibleEstimatesAndInvoices] =
    useState(true);
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  const notify = (text) => toast(text);

  const logout = () => {
    // Remove the tokens from localStorage or any other storage you are using
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");

    // Redirect the user to the login page or home page as required
    window.location.href = "/login";
  };

  function closeQrCodeModal() {
    setQrCodeModalIsOpen(false);
  }

  function closePhoneNumberModal() {
    setPhoneNumberModal(false);
  }

  useEffect(() => {
    // If there's jobData, populate the form with the job's existing data
    if (jobData) {
      setFormData({
        ...jobData,
        bill_to_phone_number: jobData.bill_to_phone_number || "",
        land_owner_phone_number: jobData.land_owner_phone_number || "",
        resident_phone_number: jobData.resident_phone_number || "",
        permit_required: jobData.permit_required || false,
        number_of_bathrooms: jobData.number_of_bathrooms || "0",
        square_footage: jobData.square_footage || "0",
        scope_of_work: jobData.scope_of_work || "",
        water: jobData.water || "",
        sewer: jobData.sewer || "",
        estimate_accepted: jobData.estimate_accepted || false,
        estimate_rejected: jobData.estimate_rejected || false,
        discount: jobData.discount || 0,
        discount_description: jobData.discount_description || "",
      });
      setLineItems(jobData.line_items || []);
    }

    setTotalAmount(jobData.amount || 0);

    const totalIncurredCount = jobData.line_items.reduce((count, item) => {
      return item.incurred || item.partial || item.final ? count + 1 : count;
    }, 0);

    setCompletedLineItems(totalIncurredCount);
  }, [jobData]);

  // Get current date in YYYY-MM-DD format
  const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // Function to find the send date of the estimate
  const getEstimateSendDate = (job) => {
    const estimate = job.invoices.find(
      (invoice) => invoice.invoice_type === "EST"
    );

    if (estimate) {
      return estimate.invoice_sent ? estimate.invoice_sent_date : "TBD";
    } else {
      return "TBD";
    }
  };

  // Get date 30 days from now in YYYY-MM-DD format
  const getDueDate = () => {
    const date = new Date();
    date.setDate(date.getDate() + 30);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleChange = (e) => {
    e.preventDefault();

    const { name, type, checked, value } = e.target;
    const actualValue = type === "checkbox" ? checked : value;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]:
        name === "permit_required"
          ? value === "true"
            ? true
            : false
          : actualValue,
    }));

    // Only proceed if the change was to the 'client' field
    if (name !== "client") {
      return;
    }

    if (value === "") {
      setFormData({
        ...formData,
        client: "",
      });
      return;
    }

    // Get the client data
    const token = localStorage.getItem("access_token");
    const headers = new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });

    fetch(
      `${window.location.protocol}//${window.location.hostname}:8443/api/clients/${value}/`,
      {
        method: "GET",
        headers: headers,
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch client");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        if (error.code === "token_not_valid") {
          logout();
        }

        let errorMessage = "";

        Object.keys(error).forEach((key) => {
          // Check if the property is an array and has at least one message
          if (Array.isArray(error[key]) && error[key].length > 0) {
            // Append the first error message of each field to the errorMessage string
            errorMessage += `${key}: ${error[key][0]}\n`;
          }
        });

        notify(`Error fetching client: ${errorMessage}`);
        console.error("Error fetching client:", error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    saveJob();
  };

  const sendTextMessage = (e, invoice) => {
    e.preventDefault();

    let textMessageData = {
      phone_number: `+1${jobData.bill_to_phone_number}`,
      message: `\nCustomer: ${jobData.bill_to_name}\nJobId: ${jobData.id}\nInvoice Amt: $${invoice.amount_due}\n${window.location.protocol}//${window.location.hostname}:3000/invoice/${invoice.guid}`,
    };

    const token = localStorage.getItem("access_token");
    fetch(
      `${window.location.protocol}//${window.location.hostname}:8443/send-sms/`,
      {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
        body: JSON.stringify(textMessageData),
      }
    )
      .then((response) => {
        if (!response.ok) {
          // Convert non-2xx HTTP responses into errors so the catch block can handle them
          return response.json().then((data) => Promise.reject(data));
        }
        return response.json();
      })
      .then((data) => {
        notify(`Successfully Sent Text Message!`);
      })
      .catch((error) => {
        let errorMessage = "";

        Object.keys(error).forEach((key) => {
          // Check if the property is an array and has at least one message
          if (Array.isArray(error[key]) && error[key].length > 0) {
            // Append the first error message of each field to the errorMessage string
            errorMessage += `${key}: ${error[key][0]}\n`;
          }
        });

        if (error.code === "token_not_valid") {
          logout();
        }

        notify(`Error sending text message: ${errorMessage}`);
        console.error("Error sending text message:", error);
      })
      .finally(() => {
        closePhoneNumberModal();
        setSelectedInvoice(null);
        console.log(8987788798778789);
      });
  };

  const saveJob = () => {
    const token = localStorage.getItem("access_token");
    const url = jobData.id
      ? `${window.location.protocol}//${window.location.hostname}:8443/api/jobs/${jobData.id}/` // Adjust the URL for updating the job
      : `${window.location.protocol}//${window.location.hostname}:8443/api/jobs/`; // Adjust the URL for adding a new job
    const method = jobData.id ? "PUT" : "POST"; // Use 'PUT' to update and 'POST' to create

    fetch(url, {
      // Adjust this URL to your jobs endpoint
      method: method,
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          // Convert non-2xx HTTP responses into errors so the catch block can handle them
          return response.json().then((data) => Promise.reject(data));
        }
        return response.json();
      })
      .then((data) => {
        notify(`Successfully Saved!`);
        refreshTables();
      })
      .catch((error) => {
        let errorMessage = "";

        Object.keys(error).forEach((key) => {
          // Check if the property is an array and has at least one message
          if (Array.isArray(error[key]) && error[key].length > 0) {
            // Append the first error message of each field to the errorMessage string
            errorMessage += `${key}: ${error[key][0]}\n`;
          }
        });

        if (error.code === "token_not_valid") {
          logout();
        }

        notify(`Error adding job: ${errorMessage}`);
        console.error("Error adding job:", error);
      });
  };

  const openCompletionModal = (e) => {
    e.preventDefault();
    setIsCompletionModalOpen(true);
  };

  const openPaymentModel = (e) => {
    e.preventDefault();
    setIsPaymentModalOpen(true);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closePaymentModel = () => {
    setIsPaymentModalOpen(false);
  };
  const closeCompletionModal = (e) => {
    setIsCompletionModalOpen(false);
  };

  const handleEstimateChange = (e) => {
    e.preventDefault();

    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;

    setEstimateData((prevData) => {
      const updatedData = { ...prevData, [name]: newValue };

      if (name === "percentage") {
        const percentageValue = parseFloat(value);
        if (!isNaN(percentageValue) && totalAmount > 0) {
          const calculatedAmountDue = (totalAmount * percentageValue) / 100;
          return { ...updatedData, amount_due: calculatedAmountDue.toFixed(2) };
        }
      }

      return updatedData;
    });
  };

  const acceptEstimate = () => {
    const token = localStorage.getItem("access_token");
    const url = jobData.id
      ? `${window.location.protocol}//${window.location.hostname}:8443/api/jobs/${jobData.id}/` // Adjust the URL for updating the job
      : `${window.location.protocol}//${window.location.hostname}:8443/api/jobs/`; // Adjust the URL for adding a new job
    const method = jobData.id ? "PUT" : "POST"; // Use 'PUT' to update and 'POST' to create

    setFormData({
      ...formData,
      status: "IPG",
      estimate_accepted: true,
      estimate_accepted_date: getCurrentDate(),
    });

    const newData = {
      ...formData,
      status: "IPG",
      estimate_accepted: true,
    };

    fetch(url, {
      // Adjust this URL to your jobs endpoint
      method: method,
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
      body: JSON.stringify(newData),
    })
      .then((response) => {
        if (!response.ok) {
          // Convert non-2xx HTTP responses into errors so the catch block can handle them
          return response.json().then((data) => Promise.reject(data));
        }
        return response.json();
      })
      .then((data) => {
        notify(`Successfully Saved!`);
        refreshTables();
      })
      .catch((error) => {
        let errorMessage = "";

        Object.keys(error).forEach((key) => {
          // Check if the property is an array and has at least one message
          if (Array.isArray(error[key]) && error[key].length > 0) {
            // Append the first error message of each field to the errorMessage string
            errorMessage += `${key}: ${error[key][0]}\n`;
          }
        });

        if (error.code === "token_not_valid") {
          logout();
        }

        notify(`Error adding job: ${errorMessage}`);
        console.error("Error adding job:", error);
      });
  };

  const rejectEstimate = () => {
    const token = localStorage.getItem("access_token");
    const url = jobData.id
      ? `${window.location.protocol}//${window.location.hostname}:8443/api/jobs/${jobData.id}/` // Adjust the URL for updating the job
      : `${window.location.protocol}//${window.location.hostname}:8443/api/jobs/`; // Adjust the URL for adding a new job
    const method = jobData.id ? "PUT" : "POST"; // Use 'PUT' to update and 'POST' to create

    let hasUnpaidEstimate = false;

    if (jobData.invoices.length > 0) {
      hasUnpaidEstimate =
        jobData.invoices[0].amount_due > jobData.invoices[0].amount_paid;
    }

    let newData;

    if (hasUnpaidEstimate) {
      setFormData({
        ...formData,
        status: "INV",
        estimate_rejected: true,
        estimate_rejected_date: getCurrentDate(),
      });

      newData = {
        ...formData,
        status: "INV",
        estimate_accepted: true,
      };
    } else {
      setFormData({
        ...formData,
        status: "CNL",
        estimate_rejected: true,
        estimate_rejected_date: getCurrentDate(),
      });

      newData = {
        ...formData,
        status: "CNL",
        estimate_accepted: true,
      };
    }

    fetch(url, {
      // Adjust this URL to your jobs endpoint
      method: method,
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
      body: JSON.stringify(newData),
    })
      .then((response) => {
        if (!response.ok) {
          // Convert non-2xx HTTP responses into errors so the catch block can handle them
          return response.json().then((data) => Promise.reject(data));
        }
        return response.json();
      })
      .then((data) => {
        notify(`Successfully Saved!`);
        refreshTables();
      })
      .catch((error) => {
        let errorMessage = "";

        Object.keys(error).forEach((key) => {
          // Check if the property is an array and has at least one message
          if (Array.isArray(error[key]) && error[key].length > 0) {
            // Append the first error message of each field to the errorMessage string
            errorMessage += `${key}: ${error[key][0]}\n`;
          }
        });

        if (error.code === "token_not_valid") {
          logout();
        }

        notify(`Error adding job: ${errorMessage}`);
        console.error("Error adding job:", error);
      });
  };

  const showQrCodeForInvoice = (invoiceGuid) => {
    setQrCodeUrl(
      `${window.location.protocol}//${window.location.hostname}/invoice/${invoiceGuid}`
    );
    setQrCodeModalIsOpen(true);
  };

  const generateFinalInvoice = () => {
    const token = localStorage.getItem("access_token");

    // Update the job completion date with current time in EST
    var completionDate = new Date().toLocaleString("en-US", {
      timeZone: "America/New_York",
    });
    var dateParts = completionDate.split(",")[0].split("/"); // Split the date by '/'
    var completion_date_string = [
      dateParts[2],
      dateParts[0],
      dateParts[1],
    ].join("-"); // Swap month and day

    fetch(
      `${window.location.protocol}//${window.location.hostname}:8443/api/jobs/${jobData.id}/`,
      {
        method: "PATCH",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
        body: JSON.stringify({
          company: jobData.company,
          user: getUserIdFromToken(token),
          completion_date: completion_date_string,
        }),
      }
    );

    let amountDue = 0.0;

    amountDue = selectedLineItems.reduce((sum, item) => {
      return sum + parseFloat(item.total_price || 0);
    }, 0);

    if (jobData.invoices.length >= 1) {
      for (var i = 0; i < jobData.invoices.length; i++) {
        if (
          jobData.invoices[i].amount_due - jobData.invoices[i].amount_paid >
          0
        ) {
          notify(
            "Final cannot be created because previous balance is still owed."
          );
          return;
        }
      }
    }

    // Calculate the percentage of the total job amount for the partial invoice
    const percentage = ((amountDue / jobData.amount) * 100).toFixed(2);

    // Prepare data for the final invoice
    const finalData = {
      job: jobData.id,
      percentage: percentage,
      issue_date: getCurrentDate(),
      due_date: getDueDate(),
      amount_due: parseFloat(amountDue).toFixed(2),
      amount_paid: "0.00",
      invoice_sent: false,
      invoice_type: "INV",
      line_items: selectedLineItems.map((item) => item.id),
    };

    // Send a POST request to create the final invoice
    fetch(
      `${window.location.protocol}//${window.location.hostname}:8443/api/invoices/`,
      {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
        body: JSON.stringify(finalData),
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to generate final invoice");
        }
        return response.json();
      })
      .then((data) => {
        notify("Final Invoice generated successfully!");

        // Now that the invoice is created, we need to update the rows to be incurred
        fetch(
          `${window.location.protocol}//${window.location.hostname}:8443/api/update-to-final/`,
          {
            method: "POST",
            headers: new Headers({
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            }),
            body: JSON.stringify(finalData.line_items),
          }
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error("Failed to update line items");
            }
            return response.json();
          })
          .then((data) => {
            notify("Line items have been marked as final.");
            setSelectedLineItems([]);
            refreshTables();
          })
          .catch((error) => {
            let errorMessage = error.message;
            notify(`Error updated line items to final: ${errorMessage}`);
            console.error("Error updated line items to final:", error);
            refreshTables();
          });
      })
      .catch((error) => {
        let errorMessage = error.message;
        notify(`Error generating final invoice: ${errorMessage}`);
        console.error("Error generating final invoice:", error);
      });
  };

  const generatePartialInvoice = () => {
    const token = localStorage.getItem("access_token");
    let depositPaid = 0.0;
    let partialAmount = 0.0;

    jobData.invoices.forEach((invoice) => {
      if (invoice.invoice_type === "EST") {
        depositPaid += parseFloat(invoice.amount_paid || 0);
      }
    });

    let incurredAmount = jobData.line_items.reduce((sum, item) => {
      if (item.incurred) {
        return sum + parseFloat(item.total_price || 0);
      }
      return sum;
    }, 0);

    partialAmount = selectedLineItems.reduce((sum, item) => {
      return sum + parseFloat(item.total_price || 0);
    }, 0);

    let totalDue = 0;
    let percentage = 0;

    if (jobData.invoices.length >= 1) {
      // We only care about the deposit being met on the first partial invoice
      let remainingDeposit = parseFloat(depositPaid) - incurredAmount;

      if (jobData.invoices.length === 1) {
        if (remainingDeposit - partialAmount > 0) {
          notify(
            "Partial cannot be created because remaining deposit has not been met."
          );
          return;
        }
      }

      for (var i = 0; i < jobData.invoices.length; i++) {
        if (
          jobData.invoices[i].amount_due - jobData.invoices[i].amount_paid >
          0
        ) {
          notify(
            "Partial cannot be created because previous balance is still owed."
          );
          return;
        }
      }

      totalDue = partialAmount - depositPaid + incurredAmount;
      percentage = ((totalDue / jobData.amount) * 100).toFixed(2);
    } else {
      totalDue = partialAmount;
      percentage = ((totalDue / jobData.amount) * 100).toFixed(2);
    }

    const partialData = {
      job: jobData.id,
      percentage: percentage,
      issue_date: getCurrentDate(),
      due_date: getDueDate(),
      amount_due: totalDue.toFixed(2),
      amount_paid: "0.00",
      invoice_sent: false,
      invoice_type: "DRW",
      line_items: selectedLineItems.map((item) => item.id),
    };

    fetch(
      `${window.location.protocol}//${window.location.hostname}:8443/api/invoices/`,
      {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
        body: JSON.stringify(partialData),
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to generate partial invoice");
        }
        return response.json();
      })
      .then((data) => {
        notify("Partial invoice has been generated.");
        fetch(
          `${window.location.protocol}//${window.location.hostname}:8443/api/update-to-partial/`,
          {
            method: "POST",
            headers: new Headers({
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            }),
            body: JSON.stringify(partialData.line_items),
          }
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error("Failed to update line items");
            }
            return response.json();
          })
          .then((data) => {
            notify("Line items have been marked as partial.");
            setSelectedLineItems([]);
            refreshTables();
          })
          .catch((error) => {
            let errorMessage = error.message;
            notify(`Error updated line items to partial: ${errorMessage}`);
            console.error("Error updated line items to partial:", error);
            refreshTables();
          });
      })
      .catch((error) => {
        let errorMessage = error.message;
        notify(`Error generating partial invoice: ${errorMessage}`);
        console.error("Error generating partial invoice:", error);
        refreshTables();
      });
  };

  const generateEstimate = () => {
    const token = localStorage.getItem("access_token");

    const estimate = {
      ...estimateData,
      job: jobData.id,
      issue_date: getCurrentDate(),
      due_date: getDueDate(),
      line_items: selectedLineItems.map((item) => item.id),
    };

    fetch(
      `${window.location.protocol}//${window.location.hostname}:8443/api/invoices/`,
      {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
        body: JSON.stringify(estimate),
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to generate estimate");
        }
        return response.json();
      })
      .then((data) => {
        notify(`Estimate generated successfully!`);

        setEstimateData({
          percentage: "",
          issue_date: getCurrentDate(),
          due_date: getDueDate(),
          status: "UNPAID",
          amount_due: "",
          amount_paid: "",
          invoice_sent: false,
          invoice_type: "EST",
        });

        // Now that the invoice is created, we need to update the rows to be incurred
        fetch(
          `${window.location.protocol}//${window.location.hostname}:8443/api/update-to-incurred/`,
          {
            method: "POST",
            headers: new Headers({
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            }),
            body: JSON.stringify(estimate.line_items),
          }
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error("Failed to update line items");
            }
            return response.json();
          })
          .then((data) => {
            notify("Line items have been marked as incurred.");
            closeModal();
            setSelectedLineItems([]);
            refreshTables();
          })
          .catch((error) => {
            let errorMessage = error.message;
            notify(`Error updated line items to incurred: ${errorMessage}`);
            console.error("Error updated line items to incurred:", error);
            refreshTables();
          });
      })
      .catch((error) => {
        let errorMessage = "";
        Object.keys(error).forEach((key) => {
          if (Array.isArray(error[key]) && error[key].length > 0) {
            errorMessage += `${key}: ${error[key][0]}\n`;
          }
        });

        notify(`Error generating estimate: ${errorMessage}`);
        console.error("Error generating estimate:", error);
      });
  };

  function updatePermitTotal(permitAmount) {
    setFormData({
      ...formData,
      permit_total: permitAmount,
    });
  }

  function toggleChangeOrder() {
    setChangeOrder((prevState) => {
      return !prevState;
    });
  }

  const handleLineItemSelection = (selectedItem) => {
    if (!selectLineItemsMode) return;

    // You can't select an item if it has already been marked as incurred, partial, or final
    if (
      (selectedItem.incurred && jobData.status !== "BID") ||
      selectedItem.partial ||
      selectedItem.final
    )
      return;
    setSelectedLineItems((prevItems) => {
      if (prevItems.some((item) => item.id === selectedItem.id)) {
        return prevItems.filter((item) => item.id !== selectedItem.id);
      } else {
        return [...prevItems, selectedItem];
      }
    });
  };

  const handleDiscountInput = (value) => {
    setFormData((prevForm) => ({ ...prevForm, discount: parseInt(value) }));
  };

  const handleDiscountDescriptionInput = (value) => {
    setFormData((prevForm) => ({ ...prevForm, discount_description: value }));
  };

  const handlePaymentFormInputChange = (e) => {
    const { name, value } = e.target;
    setPaymentFormData({ ...paymentFormData, [name]: value });
  };

  const handlePaymentFormSubmit = (e) => {
    e.preventDefault();

    if (paymentFormData.invoice === "") {
      notify("Invoice must be selected.");
      return;
    }

    const paymentData = {
      ...paymentFormData,
      payment_method: paymentMethod,
    };

    const token = localStorage.getItem("access_token");
    const url = `${window.location.protocol}//${window.location.hostname}:8443/api/payments/`;
    const method = "POST";

    fetch(url, {
      method: method,
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
      body: JSON.stringify(paymentData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to record payment");
        }
        return response.json();
      })
      .then((data) => {
        setPaymentFormData({
          payment_method: "",
          amount: "",
          invoice: "",
          check_number: "",
          name_on_card: "",
          card_number: "",
          card_expiry: "",
          card_cvc: "",
        });
        notify("Payment has been submitted.");
        closePaymentModel();
        refreshTables();
      })
      .catch((error) => {
        let errorMessage = "";
        Object.keys(error).forEach((key) => {
          if (Array.isArray(error[key]) && error[key].length > 0) {
            errorMessage += `${key}: ${error[key][0]}\n`;
          }
        });

        notify(`Error generating estimate: ${errorMessage}`);
        console.error("Error generating estimate:", error);
      });
  };

  return (
    <div className="container">
      <form onSubmit={handleSubmit} className="form">
        <div className="formGroup client">
          <input
            type="hidden"
            name="client"
            id="client"
            value={formData.client}
          />
        </div>

        <div
          className="separator clickable"
          onClick={() => setVisibleBillTo(!visibleBillTo)}
        >
          <img src={visibleBillTo ? chevronDown : chevronRight} /> Bill To
        </div>

        {visibleBillTo ? (
          <>
            <div className="formGroup">
              <label htmlFor="bill_to_name" className="label">
                <span>Bill To Name:</span>
              </label>
              <input
                id="bill_to_name"
                name="bill_to_name"
                value={formData.bill_to_name}
                onChange={handleChange}
                className="input"
              />
            </div>

            <div className="formGroup">
              <label htmlFor="bill_to_address" className="label">
                <span>Bill To Address:</span>
              </label>
              <input
                id="bill_to_address"
                name="bill_to_address"
                value={formData.bill_to_address}
                onChange={handleChange}
                className="input"
              />
            </div>

            <div className="">
              <div>
                <label htmlFor="bill_to_phone_number" className="label">
                  <span>Bill To Phone Number:</span>
                </label>
                <input
                  id="bill_to_phone_number"
                  name="bill_to_phone_number"
                  value={formData.bill_to_phone_number}
                  onChange={handleChange}
                  className="input"
                />
              </div>
              <button className="button" type="submit">
                Update Phone Number
              </button>
            </div>

            <div className="formGroup">
              <label htmlFor="bill_to_email_address" className="label">
                <span>Bill To Email Address:</span>
              </label>
              <input
                id="bill_to_email_address"
                name="bill_to_email_address"
                value={formData.bill_to_email_address}
                onChange={handleChange}
                className="input"
              />
            </div>

            <div className="formGroup">
              <button className="button" onClick={(e) => openPaymentModel(e)}>
                <img src={payIcon} className="buttonIcon" />
                Record Payment
              </button>
            </div>
          </>
        ) : (
          ""
        )}

        <div
          className="separator clickable"
          onClick={() => setVisibleLandOwner(!visibleLandOwner)}
        >
          <img src={visibleLandOwner ? chevronDown : chevronRight} /> Land Owner
        </div>

        {visibleLandOwner ? (
          <>
            <div className="formGroup">
              <label htmlFor="land_owner_name" className="label">
                <span>Land Owner Name:</span>
              </label>
              <input
                id="land_owner_name"
                name="land_owner_name"
                value={formData.land_owner_name}
                onChange={handleChange}
                className="input"
              />
            </div>

            <div className="formGroup">
              <label htmlFor="land_owner_address" className="label">
                <span>Land Owner Address:</span>
              </label>
              <input
                id="land_owner_address"
                name="land_owner_address"
                value={formData.land_owner_address}
                onChange={handleChange}
                className="input"
              />
            </div>

            <div className="formGroup">
              <label htmlFor="land_owner_phone_number" className="label">
                <span>Land Owner Phone Number:</span>
              </label>
              <input
                id="land_owner_phone_number"
                name="land_owner_phone_number"
                value={formData.land_owner_phone_number}
                onChange={handleChange}
                className="input"
              />
            </div>

            <div className="formGroup">
              <label htmlFor="land_owner_email_address" className="label">
                <span>Land Owner Email Address:</span>
              </label>
              <input
                id="land_owner_email_address"
                name="land_owner_email_address"
                value={formData.land_owner_email_address}
                onChange={handleChange}
                className="input"
              />
            </div>
          </>
        ) : (
          ""
        )}

        <div
          className="separator clickable"
          onClick={() => setVisibleResident(!visibleResident)}
        >
          <img src={visibleResident ? chevronDown : chevronRight} /> Resident
        </div>

        {visibleResident ? (
          <>
            <div className="formGroup">
              <label htmlFor="resident_name" className="label">
                <span>Resident Name:</span>
              </label>
              <input
                id="resident_name"
                name="resident_name"
                value={formData.resident_name}
                onChange={handleChange}
                className="input"
              />
            </div>

            <div className="formGroup">
              <label htmlFor="resident_phone_number" className="label">
                <span>Resident Phone Number:</span>
              </label>
              <input
                id="resident_phone_number"
                name="resident_phone_number"
                value={formData.resident_phone_number}
                onChange={handleChange}
                className="input"
              />
            </div>

            <div className="formGroup">
              <label htmlFor="resident_email_address" className="label">
                <span>Resident Email Address:</span>
              </label>
              <input
                id="resident_email_address"
                name="resident_email_address"
                value={formData.resident_email_address}
                onChange={handleChange}
                className="input"
              />
            </div>
          </>
        ) : (
          ""
        )}

        <div
          className="separator clickable"
          onClick={() => setVisibleProperty(!visibleProperty)}
        >
          <img src={visibleProperty ? chevronDown : chevronRight} /> Property
        </div>

        {visibleProperty ? (
          <>
            <div className="formGroup">
              <div className="label-container">
                <label htmlFor="job_site_address" className="label">
                  <span>Job Address:</span>
                </label>
              </div>
              <input
                id="job_site_address"
                name="job_site_address"
                value={formData.job_site_address}
                onChange={handleChange}
                className="input"
                required
              />
            </div>

            <div className="fieldset-grid">
              <div className="formGroup">
                <div className="label-container">
                  <label htmlFor="year_built" className="label">
                    <span>Year Built:</span>
                  </label>
                  <input
                    type="checkbox"
                    id="year_built_on_invoice"
                    name="year_built_on_invoice"
                    value={formData.year_built_on_invoice}
                    onChange={handleChange}
                    className="input-checkbox"
                    checked={formData.year_built_on_invoice}
                  />
                </div>
                <input
                  id="year_built"
                  name="year_built"
                  value={formData.year_built}
                  onChange={handleChange}
                  className="input"
                />
              </div>

              <div className="formGroup">
                <div className="label-container">
                  <label htmlFor="square_footage" className="label">
                    <span>Square Footage:</span>
                  </label>
                  <input
                    type="checkbox"
                    id="square_footage_on_invoice"
                    name="square_footage_on_invoice"
                    value={formData.square_footage_on_invoice}
                    onChange={handleChange}
                    className="input-checkbox"
                    checked={formData.square_footage_on_invoice}
                  />
                </div>
                <input
                  type="text"
                  id="square_footage"
                  name="square_footage"
                  value={formData.square_footage}
                  onChange={handleChange}
                  className="input"
                />
              </div>

              <div className="formGroup">
                <div className="label-container">
                  <label htmlFor="number_of_bedrooms" className="label">
                    <span># of Bedrooms:</span>
                  </label>
                  <input
                    type="checkbox"
                    id="number_of_bedrooms_on_invoice"
                    name="number_of_bedrooms_on_invoice"
                    value={formData.number_of_bedrooms_on_invoice}
                    onChange={handleChange}
                    className="input-checkbox"
                    checked={formData.number_of_bedrooms_on_invoice}
                  />
                </div>
                <input
                  type="text"
                  id="number_of_bedrooms"
                  name="number_of_bedrooms"
                  value={formData.number_of_bedrooms}
                  onChange={handleChange}
                  className="input"
                />
              </div>

              <div className="formGroup">
                <div className="label-container">
                  <label htmlFor="number_of_bathrooms" className="label">
                    <span># of Bathrooms:</span>
                  </label>
                  <input
                    type="checkbox"
                    id="number_of_bathrooms_on_invoice"
                    name="number_of_bathrooms_on_invoice"
                    value={formData.number_of_bathrooms_on_invoice}
                    onChange={handleChange}
                    className="input-checkbox"
                    checked={formData.number_of_bathrooms_on_invoice}
                  />
                </div>
                <input
                  type="text"
                  id="number_of_bathrooms"
                  name="number_of_bathrooms"
                  value={formData.number_of_bathrooms}
                  onChange={handleChange}
                  className="input"
                />
              </div>

              <div className="formGroup">
                <div className="label-container">
                  <label className="label">
                    <span>Water:</span>
                  </label>
                  <input
                    type="checkbox"
                    id="water_on_invoice"
                    name="water_on_invoice"
                    value={formData.water_on_invoice}
                    onChange={handleChange}
                    className="input-checkbox"
                    checked={formData.water_on_invoice}
                  />
                </div>
                <div>
                  <select
                    name="water"
                    onChange={handleChange}
                    className="form-control"
                    value={formData.water}
                  >
                    <option>Select Water Type</option>
                    <option value="meter">Meter</option>
                    <option value="well">Well</option>
                  </select>
                </div>
              </div>

              <div className="formGroup">
                <div className="label-container">
                  <label className="label">
                    <span>Sewer:</span>
                  </label>
                  <input
                    type="checkbox"
                    id="sewer_on_invoice"
                    name="sewer_on_invoice"
                    value={formData.sewer_on_invoice}
                    onChange={handleChange}
                    className="input-checkbox"
                    checked={formData.sewer_on_invoice}
                  />
                </div>
                <div>
                  <select
                    name="sewer"
                    onChange={handleChange}
                    className="form-control"
                    value={formData.sewer}
                  >
                    <option>Select Sewer Type</option>
                    <option value="sewer">Sewer</option>
                    <option value="septic">Septic</option>
                  </select>
                </div>
              </div>

              <div className="formGroup">
                <div className="label-container">
                  <label htmlFor="gate_code" className="label">
                    <span>Gate Code:</span>
                  </label>
                  <input
                    type="checkbox"
                    id="gate_code_on_invoice"
                    name="gate_code_on_invoice"
                    value={formData.gate_code_on_invoice}
                    onChange={handleChange}
                    className="input-checkbox"
                    checked={formData.gate_code_on_invoice}
                  />
                </div>

                <input
                  type="text"
                  id="gate_code"
                  name="gate_code"
                  value={formData.gate_code}
                  onChange={handleChange}
                  className="input"
                />
              </div>

              <div className="formGroup">
                <div className="label-container">
                  <label htmlFor="lockbox_code" className="label">
                    <span>Lockbox Code:</span>
                  </label>
                  <input
                    type="checkbox"
                    id="lockbox_code_on_invoice"
                    name="lockbox_code_on_invoice"
                    value={formData.lockbox_code_on_invoice}
                    onChange={handleChange}
                    className="input-checkbox"
                    checked={formData.lockbox_code_on_invoice}
                  />
                </div>
                <input
                  type="text"
                  id="lockbox_code"
                  name="lockbox_code"
                  value={formData.lockbox_code}
                  onChange={handleChange}
                  className="input"
                />
              </div>
            </div>

            <div className="formGroup">
              <div className="label-container">
                <label htmlFor="custom_entry" className="label">
                  <span>Custom Entry:</span>
                </label>
                <input
                  type="checkbox"
                  id="custom_entry_on_invoice"
                  name="custom_entry_on_invoice"
                  value={formData.custom_entry_on_invoice}
                  onChange={handleChange}
                  className="input-checkbox"
                  checked={formData.custom_entry_on_invoice}
                />
              </div>

              <input
                id="custom_entry"
                name="custom_entry"
                value={formData.custom_entry}
                onChange={handleChange}
                className="input"
              />
            </div>
          </>
        ) : (
          ""
        )}

        <div
          className="separator clickable"
          onClick={() => setVisibleContractor(!visibleContractor)}
        >
          <img src={visibleContractor ? chevronDown : chevronRight} />{" "}
          Contractor
        </div>

        {visibleContractor ? (
          <>
            <div className="formGroup">
              <div className="label-container">
                <label htmlFor="contractor" className="label">
                  <span>Contractor:</span>
                </label>
                <input
                  type="checkbox"
                  id="contractor_on_invoice"
                  name="contractor_on_invoice"
                  value={formData.contractor_on_invoice}
                  onChange={handleChange}
                  className="input-checkbox"
                  checked={formData.contractor_on_invoice}
                />
              </div>
              <input
                type="text"
                id="contractor"
                name="contractor"
                value={formData.contractor}
                onChange={handleChange}
                className="input"
              />
            </div>

            <div className="formGroup">
              <div className="label-container">
                <label
                  htmlFor="scope_of_work"
                  className="label label-work-scope"
                >
                  <span>Scope of Work:</span>
                </label>
                <input
                  type="checkbox"
                  id="scope_of_work_on_invoice"
                  name="scope_of_work_on_invoice"
                  value={formData.scope_of_work_on_invoice}
                  onChange={handleChange}
                  className="input-checkbox"
                  checked={formData.scope_of_work_on_invoice}
                />
              </div>
              <textarea
                id="scope_of_work"
                name="scope_of_work"
                value={formData.scope_of_work}
                onChange={handleChange}
                className="input"
              ></textarea>
            </div>

            <div className="formGroup hidden">
              <label htmlFor="permit_description" className="label">
                <span>Permit Description:</span>
              </label>
              <textarea
                id="permit_description"
                name="permit_description"
                value={formData.permit_description}
                onChange={handleChange}
                className="input"
              ></textarea>
            </div>

            <div className="fieldset-grid hidden">
              <div className="formGroup">
                <label htmlFor="permit_total" className="label">
                  <span>Permit Total:</span>
                </label>
                <input
                  type="text"
                  id="permit_total"
                  name="permit_total"
                  value={formData.permit_total}
                  onChange={handleChange}
                  className="input"
                />
              </div>
            </div>
          </>
        ) : (
          ""
        )}

        <div
          className="separator clickable"
          onClick={() => setVisibleKey(!visibleKey)}
        >
          <img src={visibleKey ? chevronDown : chevronRight} /> Change Order
        </div>

        {visibleKey ? (
          <>
            <div className="formGroup">
              {formData.estimate_accepted ? (
                <>
                  <span className="button-label">
                    <b>Change Order</b>
                  </span>
                  {changeOrder ? (
                    <button
                      type="button"
                      className="button"
                      onClick={toggleChangeOrder}
                    >
                      <div className="button-content">
                        <div className="lock-container">
                          <img
                            src={openLock}
                            alt="Unlocked"
                            className="button-lock"
                          />
                        </div>
                        <div>Add Line Item</div>
                        <div>Strike Through Line Items</div>
                        <div>Modify Line Items</div>
                      </div>
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="button"
                      onClick={toggleChangeOrder}
                    >
                      <div className="button-content">
                        <div className="lock-container">
                          <img
                            src={closedLock}
                            alt="Unlocked"
                            className="button-lock"
                          />
                        </div>
                        <div>Add Line Item</div>
                        <div>Strike Through Line Items</div>
                        <div>Modify Line Items</div>
                      </div>
                    </button>
                  )}
                </>
              ) : (
                <button
                  type="button"
                  className="button disabled"
                  disabled
                  onClick={toggleChangeOrder}
                >
                  <div className="button-content">
                    <div className="lock-container">
                      <img
                        src={closedLock}
                        alt="Unlocked"
                        className="button-lock"
                      />
                    </div>
                    <div>Add Line Item</div>
                    <div>Strike Through Line Items</div>
                    <div>Modify Line Items</div>
                  </div>
                </button>
              )}
            </div>
          </>
        ) : (
          ""
        )}

        <div
          className="separator clickable"
          onClick={() => setVisibleLineItems(!visibleLineItems)}
        >
          <img src={visibleLineItems ? chevronDown : chevronRight} /> Line Items
        </div>

        {visibleLineItems ? (
          <>
            <div className="formGroup">
              <button
                type="button"
                className="button"
                onClick={() => setSelectLineItemsMode(!selectLineItemsMode)}
              >
                <img
                  src={selectLineItemsMode ? openLock : closedLock}
                  alt="Lock"
                  className="button-lock"
                />
                {selectLineItemsMode
                  ? "Disable Selection"
                  : "Enable Selection of Line Items"}
              </button>
            </div>

            <div className="formGroup">
              <LineItemsGrid
                rowData={lineItems}
                changeOrder={changeOrder}
                job={jobData.id}
                estimateAccepted={jobData.estimate_accepted}
                selectedLineItems={selectedLineItems}
                selectLineItemsMode={selectLineItemsMode}
                onLineItemSelection={handleLineItemSelection}
                updatePermitTotal={updatePermitTotal}
                permitRequired={formData.permit_required}
                setFormData={setFormData}
                discount={formData.discount}
                discount_description={formData.discount_description}
                handleDiscountInput={handleDiscountInput}
              />
              {/* 
                    <LineItemsCard
                        rowData={lineItems} 
                        hiddenColumns={hidePermitColumn ? ['permit_price'] : []}
                    />
                    */}
            </div>

            <div className="formGroup">
              <button type="submit" className="button">
                Save
              </button>
            </div>
          </>
        ) : (
          ""
        )}
      </form>

      {visibleLineItems ? (
        <>
          {!jobData.estimate_accepted && jobData.invoices.length === 0 ? (
            <div className="formGroup">
              <button className="button" onClick={openModal}>
                Create Estimate
              </button>
            </div>
          ) : (
            ""
          )}

          {!jobData.estimate_accepted &&
          !jobData.estimate_rejected &&
          jobData.invoices.length === 1 ? (
            <>
              <div className="formGroup">
                <button className="button" onClick={openModal}>
                  Recreate Estimate
                </button>
              </div>

              <div className="fieldset-grid">
                <div className="formGroup">
                  <button className="button" onClick={acceptEstimate}>
                    Estimate Accepted
                  </button>
                </div>

                <div className="formGroup">
                  <button className="button" onClick={rejectEstimate}>
                    Estimate Rejected
                  </button>
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          {(jobData.estimate_accepted || jobData.estimate_rejected) &&
          selectedLineItems.length > 0 ? (
            <>
              {completedLineItems + selectedLineItems.length ==
              jobData.line_items.length ? (
                <div className="formGroup">
                  <button
                    className="button"
                    onClick={(e) => {
                      openCompletionModal(e, jobData);
                    }}
                  >
                    Create Final Invoice
                  </button>
                </div>
              ) : (
                <div className="formGroup">
                  <button className="button" onClick={generatePartialInvoice}>
                    Create Partial Invoice
                  </button>
                </div>
              )}
            </>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}

      <div
        className="separator clickable"
        onClick={() =>
          setVisibleEstimatesAndInvoices(!visibleEstimatesAndInvoices)
        }
      >
        <img src={visibleEstimatesAndInvoices ? chevronDown : chevronRight} />{" "}
        Estimates And Invoices
      </div>

      {visibleEstimatesAndInvoices ? (
        <>
          <div className="form-group">
            <table className="table">
              <thead>
                <tr>
                  <th>Download</th>
                  <th>Issued</th>
                  <th>Desc</th>
                  <th>Amount</th>
                  <th>SMS</th>
                </tr>
              </thead>
              <tbody>
                {jobData.invoices.map((invoice, index) => (
                  <tr key={index}>
                    <td>
                      <a
                        href={`${window.location.protocol}//${window.location.hostname}:8443/generate-invoice/${invoice.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(e) => {
                          e.preventDefault();
                          const url = `${window.location.protocol}//${window.location.hostname}:8443/generate-invoice/${invoice.id}`;
                          const newTab = window.open(url, "_blank");
                          if (newTab) {
                            newTab.focus();
                          } else {
                            // Handle the case where the popup is blocked
                            window.location.href = url;
                          }
                        }}
                        className="download-button"
                      >
                        {invoice.invoice_type === "EST"
                          ? "Estimate"
                          : invoice.invoice_type === "DRW"
                          ? "Partial"
                          : invoice.invoice_type}
                      </a>
                    </td>
                    <td>{invoice.issue_date}</td>
                    <td>
                      {(
                        (Math.abs(invoice.amount_due) / jobData.amount) *
                        100
                      ).toFixed(2)}
                      %<br />{" "}
                      {invoice.invoice_type === "EST" ? "Deposit" : "Partial"}
                    </td>
                    <td>${invoice.amount_paid || 0.0}</td>
                    <td>
                      <button
                        className="button"
                        onClick={(e) => {
                          setSelectedInvoice(invoice);
                          setPhoneNumberModal(true);
                          //   sendTextMessage(e, invoice);
                        }}
                      >
                        Text
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="formGroup">
            Estimate Sent:
            <br /> {getEstimateSendDate(jobData)}
          </div>

          <div className="formGroup">
            Invoice Sent:
            <br /> {getEstimateSendDate(jobData)}
          </div>
        </>
      ) : (
        ""
      )}

      <Modal
        isOpen={isPaymentModalOpen}
        onRequestClose={closePaymentModel}
        contentLabel="Submit Payment"
      >
        <h2>Enter Payment Information</h2>
        <div className="formGroup">
          <label htmlFor="invoice">Select Invoice</label>
          <select
            name="invoice"
            id="invoice"
            className="input"
            onChange={handlePaymentFormInputChange}
          >
            <option value="">Select Invoice</option>
            {jobData.invoices.map((invoice, index) => (
              <option key={invoice.id} value={invoice.id}>
                {invoice.invoice_type} - $
                {invoice.amount_due -
                  invoice.payments.reduce(
                    (total, payment) => total + payment.amount,
                    0
                  )}{" "}
                Due
              </option>
            ))}
          </select>
        </div>

        <div className="formGroup">
          <label>
            <input
              type="radio"
              name="payment_method"
              value="cash"
              checked={paymentMethod === "cash"}
              onChange={() => setPaymentMethod("cash")}
            />
            Cash
          </label>
          <label>
            <input
              type="radio"
              name="payment_method"
              value="check"
              checked={paymentMethod === "check"}
              onChange={() => setPaymentMethod("check")}
            />
            Check
          </label>
          <label>
            <input
              type="radio"
              name="payment_method"
              value="card"
              checked={paymentMethod === "card"}
              onChange={() => setPaymentMethod("card")}
            />
            Credit Card
          </label>
          <label>
            <input
              type="radio"
              name="payment_method"
              value="discount"
              checked={paymentMethod === "discount"}
              onChange={() => setPaymentMethod("discount")}
            />
            Apply Discount
          </label>
        </div>

        <div className="formGroup">
          <label>
            Amount:
            <input
              type="number"
              name="amount"
              value={paymentFormData.amount}
              onChange={handlePaymentFormInputChange}
              className="input"
            />
          </label>
        </div>

        {paymentMethod === "check" && (
          <div>
            <label>
              Check Number:
              <input
                type="text"
                name="check_number"
                value={paymentFormData.check_number}
                onChange={handlePaymentFormInputChange}
                className="input"
              />
            </label>
          </div>
        )}

        {paymentMethod === "card" && (
          <div className="formGroup">
            <label>
              Card Number:
              <input
                type="text"
                name="card_number"
                value={paymentFormData.card_number}
                onChange={handlePaymentFormInputChange}
                className="input"
              />
            </label>
            <label>
              Expiry Date:
              <input
                type="text"
                name="card_expiry"
                value={paymentFormData.card_expiry}
                onChange={handlePaymentFormInputChange}
                placeholder="MM/YY"
                className="input"
              />
            </label>
            <label>
              CVC:
              <input
                type="text"
                name="card_cvc"
                value={paymentFormData.card_cvc}
                onChange={handlePaymentFormInputChange}
                className="input"
              />
            </label>
          </div>
        )}

        <button
          type="button"
          className="button"
          onClick={(e) => handlePaymentFormSubmit(e)}
        >
          Submit Payment
        </button>
        <button type="button" onClick={closePaymentModel} className="button">
          Cancel
        </button>
      </Modal>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Generate Estimate"
      >
        <h2>Generate Estimate</h2>
        <div>
          <p>
            Total Line Items:{" "}
            {jobData.permit_total > 0 ? lineItems.length + 1 : lineItems.length}
            <br />
            Total amount: {totalAmount}
          </p>
          <div className="formGroup">
            <label htmlFor="percentage" className="label">
              Percentage:
            </label>
            <input
              type="number"
              id="percentage"
              name="percentage"
              value={estimateData.percentage}
              onChange={handleEstimateChange}
              className="input"
            />
          </div>
          <div className="formGroup">
            <label htmlFor="amount_due" className="label">
              Amount Due:
            </label>
            <input
              type="number"
              id="amount_due"
              name="amount_due"
              value={estimateData.amount_due}
              onChange={handleEstimateChange}
              className="input"
            />
          </div>
          <div className="formGroup">
            <button type="button" onClick={generateEstimate} className="button">
              Save
            </button>
            <button type="button" onClick={closeModal} className="button">
              Cancel
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isCompletionModalOpen}
        onRequestClose={closeCompletionModal}
        contentLabel="Completion Time"
      >
        <div className="modalContent">
          <h3>
            This action will update completion time. Would you still like to
            proceed?
          </h3>
          <div className="formGroup">
            <button
              type="submit"
              className="button"
              onClick={generateFinalInvoice}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={qrCodeModalIsOpen}
        onRequestClose={closeQrCodeModal}
        contentLabel="Scan QR Code to See Your Job Info"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "4000",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            zIndex: "5000",
            overflow: "auto",
            maxHeight: "80%",
            width: "90%", // Responsive width
          },
        }}
      >
        <div style={{ textAlign: "center" }}>
          <h2 style={{ textAlign: "center", marginBottom: "2em" }}>
            Invoice Info
            <button onClick={closeQrCodeModal} className="closeButton">
              Close
            </button>
          </h2>
          <QRCodeCanvas value={qrCodeUrl} size={400} />
          <p>Scan To See Your Invoice</p>
        </div>
      </Modal>

      <Modal
        isOpen={phoneNumberModal}
        onRequestClose={closePhoneNumberModal}
        contentLabel="SMS Submit"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "4000",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            zIndex: "5000",
            overflow: "auto",
            maxHeight: "80%",
            width: "90%",
          },
        }}
      >
        <div
          style={{
            textAlign: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h2 style={{ textAlign: "center" }}>
            Send SMS to this phone number?
          </h2>
          <p>{jobData?.bill_to_phone_number}</p>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              marginTop: "20px",
            }}
          >
            <button
              onClick={(e) => sendTextMessage(e, selectedInvoice)}
              className="button"
            >
              Yes
            </button>
            <button onClick={() => closePhoneNumberModal()} className="button">
              No
            </button>
          </div>
        </div>
      </Modal>

      <ToastContainer />
    </div>
  );
};

export default EditJob;
