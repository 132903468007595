import React, { useEffect, useState } from "react";
import Modal from 'react-modal';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useParams } from "react-router-dom";
import "./index.css";

export default function JobDetails() {
  const { guid } = useParams(); // Extract the GUID from the URL
  const [job, setJob] = useState(null);
  const [error, setError] = useState(null);
  const [receiveEmails, setReceiveEmails] = useState(true);
  const [receiveTextMessages, setReceiveTextMessages] = useState(true);
  const [photoCarouselModalIsOpen, setPhotoCarouselModalIsOpen] = useState(false);

  function openPhotoCarouselModal(photos) {
    setPhotoCarouselModalIsOpen(true);
}

    function closePhotoCarouselModal() {
        setPhotoCarouselModalIsOpen(false);
    }

  const updateEmailPreference = (value, guid) => {
    let url = `${window.location.protocol}//${window.location.hostname}:8443/api/job/${guid}/update-email-preference`;

    

    fetch(url, { // Adjust this URL to your jobs endpoint
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/json',
        }),
        body: JSON.stringify({ receive_emails: value }),
    })
    .then(response => {
        if (!response.ok) {
            // Convert non-2xx HTTP responses into errors so the catch block can handle them
            return response.json().then(data => Promise.reject(data));
        }
        return response.json();
    })
    .then(data => {
        console.log(data);
    })
    .catch(error => {
        let errorMessage = '';

        Object.keys(error).forEach(key => {
            // Check if the property is an array and has at least one message
            if (Array.isArray(error[key]) && error[key].length > 0) {
                // Append the first error message of each field to the errorMessage string
                errorMessage += `${key}: ${error[key][0]}\n`;
            }
        });

        //if (error.code == 'token_not_valid') {
        //    logout();
        //}

        //notify(`Error adding job: ${errorMessage}`);
        console.error('Error adding job:', error);
    });
  };

  const updateTextMessagePreference = (value, guid) => {
    let url = `${window.location.protocol}//${window.location.hostname}:8443/api/job/${guid}/update-text-message-preference`;

    fetch(url, { // Adjust this URL to your jobs endpoint
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/json',
        }),
        body: JSON.stringify({ receive_text_messages: value }),
    })
    .then(response => {
        if (!response.ok) {
            // Convert non-2xx HTTP responses into errors so the catch block can handle them
            return response.json().then(data => Promise.reject(data));
        }
        return response.json();
    })
    .then(data => {
        console.log(data);
    })
    .catch(error => {
        let errorMessage = '';

        Object.keys(error).forEach(key => {
            // Check if the property is an array and has at least one message
            if (Array.isArray(error[key]) && error[key].length > 0) {
                // Append the first error message of each field to the errorMessage string
                errorMessage += `${key}: ${error[key][0]}\n`;
            }
        });

        //if (error.code == 'token_not_valid') {
        //    logout();
        //}

        //notify(`Error adding job: ${errorMessage}`);
        console.error('Error adding job:', error);
    });
  };

  useEffect(() => {
    // Fetch the invoice details from the Django backend
    fetch(`${window.location.protocol}//${window.location.hostname}:8443/api/job/${guid}/`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch invoice details.");
        }
        return response.json();
      })
      .then((data) => {
        setJob(data);
        setReceiveEmails(data.receive_emails);
        setReceiveTextMessages(data.receive_text_messages);
      })
      .catch((err) => {
        setError(err.message);
      });
  }, [guid]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!job) {
    return <div>Loading...</div>;
  }

  return (
    <>
        <div className="container">
            <h1>Job {job.job_number}</h1>

            <div className="customer-container">
                {job.bill_to_name !== null && job.bill_to_name !== "" && (
                    <div className="billToName">
                        {job.bill_to_name}
                    </div>
                )}

                {job.bill_to_address !== null && job.bill_to_address !== "" && (
                    <div className="billToAddress">
                        {job.bill_to_address}
                    </div>
                )}

                {(job.bill_to_phone_number?.trim() || job.bill_to_email_address?.trim()) && (
                    <div className="billToContact">
                        {job.bill_to_phone_number?.trim() && <span className="billToPhoneNumber">{job.bill_to_phone_number}</span>}
                        {job.bill_to_phone_number?.trim() && job.bill_to_email_address?.trim() && " | "}
                        {job.bill_to_email_address?.trim() && <span className="billToEmailAddress">{job.bill_to_email_address}</span>}
                    </div>
                )}

            </div>

            <div>
                <button className="button" onClick={() => openPhotoCarouselModal()}>Photos</button>
            </div>

            <table className="table">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Description</th>
                        <th>Labor</th>
                        <th>Materials</th>
                        <th>Equipment</th>
                        <th>Permit</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {job.line_items.map((item, index) => (
                        <tr key={index}>
                            <td>{item.line_item_number}</td>
                            <td>{item.description}</td>
                            <td>{item.labor_price}</td>
                            <td>{item.materials_price}</td>
                            <td>{item.equipment_price}</td>
                            <td>{item.permit_price}</td>
                            <td>{item.total_price}</td>
                        </tr>
                
                    ))}
                </tbody>
            </table>    

            <h3>Invoices</h3>
            {job.invoices.map((invoice, index) => (
                <div key={index}>
                    <a className="invoice-button" href={`${window.location.protocol}//${window.location.hostname}/invoice/${invoice.guid}`}><span>{invoice.invoice_type}</span></a>
                    Issued: {invoice.issue_date} Amount Due: {invoice.amount_due} Due Date: {invoice.due_date} Status: {invoice.status}
                </div>
            ))}
                
            <div>
                <label>
                    <input
                        type="checkbox"
                        checked={receiveEmails}
                        onChange={() => {
                            setReceiveEmails(!receiveEmails);
                            updateEmailPreference(!receiveEmails, job.guid);
                        }}
                    /> 
                    Receive Emails related to this job
                </label>
                <br />
                <label>
                    <input
                        type="checkbox"
                        checked={receiveTextMessages}
                        onChange={() => {
                            setReceiveTextMessages(!receiveTextMessages);
                            updateTextMessagePreference(!receiveTextMessages, job.guid);
                        }}
                    /> 
                    Receive Text Messages related to this job
                </label>
            </div>

            <Modal
                isOpen={photoCarouselModalIsOpen}
                onRequestClose={closePhotoCarouselModal}
                contentLabel="Job Photos Carousel"
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: '4000',
                    },
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: '5000',
                        overflow: 'auto',
                        maxHeight: '80%',
                        width: '90%',  // Responsive width
                    }
                }}
            >
                <h2>Job Photos <button onClick={closePhotoCarouselModal} className="closeButton">Close</button></h2>
                <Carousel>
                    {job.photos.map(photo => (
                        <div key={photo.id}>
                            <img src={`${window.location.protocol}//${window.location.hostname}:8443${photo.photo}`} alt={photo.name} />
                            <p className="legend">{photo.description}</p>
                        </div>
                    ))}
                </Carousel>
            </Modal>
        </div>
    </>
  );
};
