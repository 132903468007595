import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/navBar';
import EditJob from './components/editjob';
import Login from './components/login';
import HomePage from './pages/homePage';
import WeatherWidget from './components/weatherWidget';
import ProtectedRoute from './components/protectedRoute';
import AddressPage from './pages/addressesPage';
import CustomersPage from './pages/customersPage';
import MapPage from './pages/mapPage';
import EmployeePage from './pages/employeePage';
import InvoiceDetails from './pages/invoiceDetailsPage';
import JobDetails from './pages/jobDetailsPage';
import Modal from 'react-modal';
import Footer from './components/footer';
import { JobsProvider } from './components/jobContext';
import { TokenValidationProvider } from './components/tokenValidationContext';
import { SelectionProvider } from './components/selectionContext';


import './App.css';
import PaymentSuccessPage from './pages/paymentSuccessPage';
import PortalPage from './pages/portalPage';

Modal.setAppElement('#root');  // Improves accessibility

function App() {
  return (
    <JobsProvider>
      <SelectionProvider>
          <Router>
          <TokenValidationProvider>
            <div className="App">
              <header className="App-header">
                <WeatherWidget />
                <Navbar />
              </header>

              <Routes>
                <Route
                  path="/"
                  element={
                    <ProtectedRoute>
                      <HomePage />
                    </ProtectedRoute>
                  }
                />

                <Route path="/invoice/:guid" element={<InvoiceDetails />} />
                <Route path="/job/:guid" element={<JobDetails />} />

                <Route path="/edit-job/:jobId" element={
                    <ProtectedRoute>
                      <EditJob />
                    </ProtectedRoute>
                } />

                <Route path="/addresses" element={
                    <ProtectedRoute>
                      <AddressPage />
                    </ProtectedRoute>
                } />

                <Route path="/customers" element={
                    <ProtectedRoute>
                      <CustomersPage />
                    </ProtectedRoute>
                } />

                <Route path="/map" element={
                    <ProtectedRoute>
                      <MapPage />
                    </ProtectedRoute>
                } />

                <Route path="/staff" element={
                    <ProtectedRoute>
                      <EmployeePage />
                    </ProtectedRoute>
                } />

                
                <Route path="/payment-success" element={
                    <ProtectedRoute>
                      <PaymentSuccessPage />
                    </ProtectedRoute>
                } />

                <Route path="/portal" element={
                    <ProtectedRoute>
                      <PortalPage />
                    </ProtectedRoute>
                } />


                <Route path="/login" element={<Login />} />
              </Routes>
            </div>
                
            <Footer />
          </TokenValidationProvider>
        </Router>
      </SelectionProvider>
    </JobsProvider>
  );
}

export default App;
