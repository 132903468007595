import React, { useEffect, useState, useCallback } from 'react';
import SummaryTable from '../../components/summaryTable';
import JobData from '../../components/jobData';
import { useJobs } from '../../components/jobContext';
import { useSelection } from '../../components/selectionContext';
import { useNavigate, useLocation } from 'react-router-dom';
import './index.css';

const HomePage = () => {
    const navigate = useNavigate();
    const { jobsData, refreshTables, isLoading } = useJobs();
    const { selectedJobs } = useSelection();
    const { hash } = useLocation();
    const [childReadyCount, setChildReadyCount] = useState(0);
    const jobTypes = Object.keys(jobsData);
    const handleChildRenderComplete = useCallback(() => {
        setChildReadyCount((prev) => prev + 1);
      }, []);

    useEffect(() => {
        refreshTables();
    }, []);

    useEffect(() => {
        if (childReadyCount === jobTypes.length && hash) {
          // e.g. do your scroll
          requestAnimationFrame(() => {
            const element = document.querySelector(hash);
            if (element) {
              element.scrollIntoView({ behavior: 'smooth' });
            }
          });
        }
      }, [childReadyCount, hash, jobTypes]);
    
      if (isLoading) {
        return <div>Loading your jobs...</div>;
      }

    const formatString = (str) => {
        // Capitalize the first letter
        let formattedStr = str.charAt(0).toUpperCase() + str.slice(1);

        // Insert spaces before each capital letter except the first one
        formattedStr = formattedStr.replace(/([A-Z])/g, ' $1');

        // Remove the leading space if it exists (in case the first character is already capitalized)
        if (formattedStr.charAt(0) === ' ') {
            formattedStr = formattedStr.slice(1);
        }

        return formattedStr;
    };

    const handleNavigation = () => {
        navigate('/map', { state: { selectedJobs } });
      };

  return (
    <>
        <div className="table-container">
            <SummaryTable 
                jobsInProgress={jobsData['jobsInProgress'].length} 
                bidJobs={jobsData['bids'].length}
                completedJobsNotInvoiced={jobsData['completedJobsNotInvoiced'].length}
                invoicedJobs={jobsData['invoicedJobs'].length}
                invoicedPile={jobsData['invoicedPile'].length}
                paidJobs={jobsData['paidJobs'].length}
                cancelledJobs={jobsData['cancelledJobs'].length}
            />
            {selectedJobs.length > 0 && (
                <button class="map-button" onClick={handleNavigation}>Show on Map</button>
            )}
        </div>

        {Object.keys(jobsData).map((jobType) => (
            <div className="tablesec" key={jobType}>
                <div className="section_header">{formatString(jobType)} <span className="table-total">{jobsData[jobType].length}</span></div>
                
                <JobData 
                    data={jobsData[jobType]}
                    refreshTables={refreshTables} 
                    onRenderComplete={
                        handleChildRenderComplete
                    }
                />
            </div>
            
        ))}
    </>
  );
};

export default HomePage;