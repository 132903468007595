import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import PayPal from "../../components/PayPal";
import InvoiceDownloadButton from "../../components/invoiceDownloadButton";
import "./index.css";
import { AgGridReact } from 'ag-grid-react';

export default function InvoiceDetails() {
  const { guid } = useParams(); // Extract the GUID from the URL
  const [invoice, setInvoice] = useState(null);
  const [error, setError] = useState(null);
  const [gridData, setGridData] = useState([]);
  const gridRef = useRef(null);

  const gridOptions ={
          onGridSizeChanged: params => {
              params.api.sizeColumnsToFit();
          },
          
          singleClickEdit: false,
          suppressMovableColumns: true,    // Disable column moving
          suppressDragLeaveHidesColumns: true, // Prevent columns from hiding when dragged
          stopEditingWhenCellsLoseFocus: false,
          suppressCellFocus: false,
          suppressClickEdit: !false,
          enterMovesDownAfterEdit: true,
          valueSetter: (params) => {
              if (params.newValue !== params.oldValue) {
                  params.data[params.colDef.field] = params.newValue;
                  return true;
              }
              return false;
          },
          rowDragManaged: true,
          animateRows: true,
          //onRowDragEnd: onRowDragEnd,
          //onRowClicked: handleRowClick, // Add this to handle row clicks
          // Allows you to move to the next cell with Tab
          tabToNextCell: (params) => {
              const nextCell = params.nextCellPosition;
              return nextCell;
          },
      };

      const columnDefs = [
        {
            headerName: "#", 
            field: 'line_item_number',
            pinned: 'left',
            width: 50,  // Set a fixed width for the index column
            sortable: false, // Indexes typically don't need to be sortable
            filter: false,  // Filtering not required for index numbers
            rowDrag: false
        },
        { headerName: "Description", field: "description", editable: false, minWidth: 240, pinned: 'left' },
        { headerName: "Labor $", field: "labor_price", editable: false, minWidth: 120 },
        { headerName: "Materials $", field: "materials_price", editable: false, minWidth: 120 },
        { headerName: "Equipment $", field: "equipment_price", editable: false, minWidth: 120 },
        { headerName: "Other $", field: "permit_price", editable: false, minWidth: 120, hide: !false },
        { headerName: "Total $", field: "total_price", editable: (params) => params.data.description == 'Discount', minWidth: 120 }
    ].map(col => ({
        ...col,
        //hide: invoice.line_items.includes(col.field)
    }));

  useEffect(() => {
    // Fetch the invoice details from the Django backend
    fetch(`${window.location.protocol}//${window.location.hostname}:8443/api/invoice/${guid}/`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch invoice details.");
        }
        return response.json();
      })
      .then((data) => {
        setInvoice(data);
      })
      .catch((err) => {
        setError(err.message);
      });
  }, [guid]);
  const sortAndUpdateLineNumbers = (data) => {
    return data
        .filter(item => !item.isFooter)
        .sort((a, b) => a.line_item_number - b.line_item_number)
        .map((item, index) => ({ ...item, line_item_number: index + 1 }));
};
const calculateTotalPrice = (item) => {
  const laborPrice = parseFloat(item.labor_price) || 0;
  const materialsPrice = parseFloat(item.materials_price) || 0;
  const equipmentPrice = parseFloat(item.equipment_price) || 0;
  const permitPrice = parseFloat(item.permit_price) || 0;
  return laborPrice + materialsPrice + equipmentPrice + permitPrice;
};

const calculateSumsAndPercentages = (data) => {
  const totals = {
      labor_price: 0,
      materials_price: 0,
      equipment_price: 0,
      permit_price: 0,
      total_price: 0,
  };

  console.log(data);

  data.forEach(item => {
      totals.labor_price += parseFloat(item.labor_price) || 0;
      totals.materials_price += parseFloat(item.materials_price) || 0;
      totals.equipment_price += parseFloat(item.equipment_price) || 0;
      totals.permit_price += parseFloat(item.permit_price) || 0;
      totals.total_price += calculateTotalPrice(item);
  });

  console.log(totals);

  //updatePermitTotal(totals.permit_price);

  const percentages = {
      labor_price: 0,
      materials_price: 0,
      equipment_price: 0,
      permit_price: 0,
      total_price: 0
  };

  console.log(totals.total_price);
  const grandTotal = totals.total_price;
  console.log(0);
  console.log(grandTotal);
  if (grandTotal > 0) {
      percentages.labor_price = (totals.labor_price / grandTotal) * 100;
      percentages.materials_price = (totals.materials_price / grandTotal) * 100;
      percentages.equipment_price = (totals.equipment_price / grandTotal) * 100;
      percentages.permit_price = (totals.permit_price / grandTotal) * 100;
      percentages.total_price = (totals.total_price / grandTotal) * 100;
  }

  return { totals, percentages };
};


const getFooterRows = (data) => {
  const { totals, percentages } = calculateSumsAndPercentages(data);
  return [
      {
          description: 'Totals',
          labor_price: totals.labor_price,
          materials_price: totals.materials_price,
          equipment_price: totals.equipment_price,
          permit_price: totals.permit_price,
          total_price: totals.total_price,
          isFooter: true,
          editable: false
      },
      {
          description: 'Percentages',
          labor_price: percentages.labor_price.toFixed(2) + '%',
          materials_price: percentages.materials_price.toFixed(2) + '%',
          equipment_price: percentages.equipment_price.toFixed(2) + '%',
          permit_price: percentages.permit_price.toFixed(2) + '%',
          total_price: percentages.total_price.toFixed(2) + '%',
          isFooter: true,
          editable: false
      }
  ];
};


  const updateGridData = (data) => {
          if (data != null) {
            const sortedData = sortAndUpdateLineNumbers(data);
            setGridData([...sortedData, ...getFooterRows(sortedData)]);
          } 
      };
  
      useEffect(() => {
        if (invoice) {
          updateGridData(invoice.line_items);
          if (gridRef.current && gridRef.current.api) {
            gridRef.current.api.sizeColumnsToFit();
          }
        }
      }, [invoice]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!invoice) {
    return <div>Loading...</div>;
  }

  const getRowClass = (params) => {
    if (params.data.isFooter) return 'no-drag';
    if (params.data.partial) return 'partial-row';
    if (params.data.final) return 'final-row';

    // for(let i=0; i<gridData.length; i++) {
    //   if (gridData[i].id === params.data.id) {
    //       return 'currently-selected-row';
    //   }
    // }

    if (params.data.incurred) return 'incurred-row';

    return '';
};

  return (
        <div className="container">
            <h1>Invoice Details</h1>
            <p><strong>ID:</strong> {invoice.id}</p>
            <p><strong>Amount Due:</strong> {invoice.amount_due}</p>
            <p><strong>Date:</strong> {invoice.due_date}</p>
            <p><strong>Status:</strong> {invoice.status}</p>
           
              <div className="ag-theme-alpine" style={{ width: '100%', height: '100%' }} ref={gridRef}>

                  <AgGridReact 
                      key={JSON.stringify({gridData})}
                      columnDefs={columnDefs}
                      defaultColDef={{
                          resizable: false,
                          sortable: false,
                          filter: false,
                          flex: 1,
                          editable: false
                      }}
                      rowData={gridData}
                      domLayout='autoHeight'
                      animateRows={true}
                      filter={false}
                      pagination={false}
                      paginationPageSize={10}
                      gridOptions={gridOptions}
                      getRowClass={getRowClass}>
                  </AgGridReact>
              
                       
            </div>
            <div className="button-container">
              <p>
                <PayPal invoice={invoice} />
              </p>
              <a
                  href={`${window.location.protocol}//${window.location.hostname}:8443/generate-invoice/${invoice.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => {
                      e.preventDefault();
                      const url = `${window.location.protocol}//${window.location.hostname}:8443/generate-invoice/${invoice.id}`;
                      const newTab = window.open(url, '_blank');
                      if (newTab) {
                          newTab.focus();
                      } else {
                          // Handle the case where the popup is blocked
                          window.location.href = url;
                      }
                  }}
                  className="download-button invoice-download-button"
              >
                  DOWNLOAD {invoice.invoice_type === "EST" ? "Estimate" : invoice.invoice_type === "DRW" ? "Partial" : invoice.invoice_type}
                </a>
            </div>
        </div>

  );
};
